<template>
  <div>
      <div class="form-div">
        <Form class="isForm" label-position="right" :label-width="150">
          <FormItem class="isNoWidth">
             <span slot="label" class="validate">身份类型:</span>
             <Select v-model="formDataHome.userType" clearable >
                <Option
                v-for="item in registeredType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
          </FormItem>
          <FormItem class="isNoWidth">
             <span slot="label" class="validate">是否家庭户代表:</span>
              <Select v-model="formDataHome.contact" clearable >
                <Option value="1">是</Option>
                <Option value="2">否</Option>
              </Select>
          </FormItem>
        </Form>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    formDataHome:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
        familyMemberId:{
            type:Object,
            default:()=>{
                return ''
            }
        }
  },
  data(){
    return{
      registeredType:[]
    }
  },
  created(){
      this.getDicType('ONLINE_HOME_USER_TYPE','registeredType')
      this.getDetail()
  },
  methods:{
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUserOnlineHomeInfo',{
              userId:this.userId,
              familyMemberId:this.familyMemberId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getHome',res.data)
              }else{
                this.$Message.error({
                  content:'网上家园信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
        
  },
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>